.form-image-input {
  min-width: 200px;
  min-height: 200px;
  position: relative;
  display: flex;
  padding: 0;
  background-color: rgba(black, 0.1);
  .image {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100% !important;
    width: 100% !important;
    flex: 1;
    position: absolute;
  }
  input[type=file] {
    height: 100% !important;
    width: 100% !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
    flex: 1;
    filter: opacity(0);
    cursor: pointer;
  }

  label {
    display: flex;
    flex: 1;
    max-height: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
    margin: 0 !important;
    pointer-events: none;
    .upload-icon {
      flex: 1;
      height: 0;
      min-height: 50px;
      max-height: 100px;
      margin: auto;
    }
  }

  &.dragging-over {
    label {
      background: rgba(black, 0.2);
    }
  }

  &.has-image {
    label {
      //display: none;
      filter: opacity(0);
      .upload-icon {
        filter: brightness(130%);
      }
    }

    &:hover, &.dragging-over {
      label {
        filter: opacity(1);
        background: rgba(black, 0.2);
        display: flex;
      }
    }
  }

  &.disabled {
    input, label {
      display: none !important;
      pointer-events: none !important;
    }
  }

  &:not(.interactive) {
    label, input {
      pointer-events: none;
      display: none;
    }
  }
}
