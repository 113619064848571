

@mixin rtl {
  @at-root html[dir=rtl] & {
    @content;
  }
}


@mixin not-rtl {
  @at-root html:not([dir=rtl]) & {
    @content;
  }
}
