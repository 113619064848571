@import "scss/rtl";
@import "scss/connectstrap_variables";
@import "bootstrap/scss/bootstrap.scss";
@import "scss/connectstrap_components";

@import "toastify-js/src/toastify.css";

@import "@fortawesome/fontawesome-free/css/all.css";

input[type="radio"] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  color: $primary;
  display: inline-block;
  cursor: pointer;
  &::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f111';
  }

  &:checked::before {
    content: '\f058';
    font-weight: bold;
  }
}




 
button.btn.btn-lg.dash-btn.phone.w-100 {
    background: white;
    color: rgb(230, 58, 70);
    border: 1px solid #e63a46;
    padding: 1.4rem 7rem;
    




   &:hover {

    background: rgb(230, 58, 70);
    color: white;
    
    i.fas.fa-5x.fa-file-invoice.icon.text-connect.mb-3 {
      color: white !important
    }
  
    i.fas.fa-user-plus.icon.fa-5x.text-connect.mb-3 {
      color: white !important
    }
  
   }
     
}

@media (min-width: 320px) and (max-width: 480px){
  button.btn.btn-lg.dash-btn.phone.w-100 {
        padding: 0 !important;
        margin-top: 12%;
     }
  
  .p-3.p-md-5.border.border-end-0.border-top-0.border-bottom-0.border-4.border-connect {
     border: 0 !important;
  }
}


@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  button.btn.btn-lg.dash-btn.phone.w-100 {
    padding: 0 !important;
    margin-top: 12%;
 }
  
}
  
.dash-row {
   margin: auto;
}


.card {
  border: none;

  border-radius: 5px;
  overflow: hidden
}

.inner-card {
  height: 20px;
  background-color: #eee
}

.card-1 {
  height: 200px;
  background-color: #eee
}

.card-2 {
  height: 130px
}

.h-screen {
  height: 100vh
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite
}

@keyframes pulse {
  50% {
      opacity: .9
  }
}



