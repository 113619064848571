@import url('https://db.onlinewebfonts.com/c/125063e353c1f65c55ba1e3811969217?family=GE+SS+Two+Medium');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

$primary: #e63a46;
$dark: #112136;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

$body-color: $dark;
$theme-colors: (
  primary: $primary,
  connect: $primary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  dark: $dark,
  light: #f5f5f5
);

$font-family-base: "Montserrat", 'GE SS Two Medium', sans-serif;

$easing: cubic-bezier(0.4, 0.0, 0.2, 1);

$border-radius: 20px;
$border-radius-sm: 20px;
$border-radius-lg: 20px;
$border-radius-pill: 20px;
