@each $name, $color in $theme-colors {
  .btn-blob-#{$name} {
    color: color-contrast($background: $color);
    position: relative;
    z-index: 1;
    font-weight: bold;
    box-shadow: none !important;

    .text-focus {
      position: relative;
      &::before {
        content: '';
        transition: transform 0.4s $easing;
        transform-origin: center center;
        position: absolute;
        display: block;
        left: 0; top: 100%;
        width: 100%;
        height: 0.2em;
        margin-top: 0.2em;
        background-color: $color;
      }
    }

    &::before {
      filter: drop-shadow(0px 4px 9px rgba($color, 0.68));
      content: '⬤';
      font-family: sans-serif;
      position: absolute;
      top: 50%;
      z-index: -1;
      font-size: 3em;
      line-height: 0.75em;
      display: block;
      height: 100%;
      color: $color;
      transform: translateY(-50%);
      @include rtl {
        transition: right 0.4s $easing, transform 0.4s $easing;
        right: 0;
      }
      @include not-rtl {
        transition: left 0.4s $easing, transform 0.4s $easing;
        left: 0;
      }
    }

    &:hover, &:focus {
      .text-focus::before {
        transform: scale(0);
      }
      &::before {
        @include rtl {
          transform: translate(100%, -50%);
          right: 100%;
        }
        @include not-rtl {
          transform: translate(-100%, -50%);
          left: 100%;
        }
      }
    }
  }
}
